/*
* Webfonts served by typotheque.com
*
* To make the font files as small as possible,
* most glyphs and OpenType features were removed.
* To edit the fonts, add more language support,
* log it to your Typotheque account:
* http://www.typotheque.com/my_account/webfonts
*
* The Typotheque Font Software is protected under domestic
* and international trademark and copyright law.
* Modifying, reverse engineering or otherwise copying
* of Typotheque Font Software is illegal.
* http://www.typotheque.com/licensing/
*
* (c) 2022, Typotheque
* Generated: 2022-01-18 15:46:04
*/

@font-face {
  font-family: "Ping Regular";
  src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011453-002844-766cee94a5ebf093e028142774c038ca.woff2")
      format("woff2"),
    url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011453-002844-766cee94a5ebf093e028142774c038ca.woff")
      format("woff");
  font-display: auto;
}
@font-face {
  font-family: "Ping Medium";
  src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011453-002846-60949cb77f1d4d114e5cd34bdb92c87a.woff2")
      format("woff2"),
    url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011453-002846-60949cb77f1d4d114e5cd34bdb92c87a.woff")
      format("woff");
  font-display: auto;
}
@font-face {
  font-family: "Ping Bold";
  src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011453-002848-739a53b604217618c83d53722d793a31.woff2")
      format("woff2"),
    url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011453-002848-739a53b604217618c83d53722d793a31.woff")
      format("woff");
  font-display: auto;
}
